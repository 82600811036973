import React from "react";

import Layout   from '../components/layout';
import Stage    from "../components/molecules/stage/stage";
import Seo      from "../components/atoms/seo/seo";
import Privacy  from "../components/molecules/modalContent/Privacy";

export default () => (
  <>
    <Seo
      title="Datenschutz"
      canonical="datenschutz"
    />
    <Layout>
      <Stage>
        <Privacy />
      </Stage>
    </Layout>
  </>
)
